/** @jsx jsx */
import { memo, ReactElement, useCallback } from "react";
import { jsx, BoxProps, Box, Text, Flex } from "theme-ui";
import { Token } from "../../redux/root";
import TokenIcon from "../common/TokenIcon";

export interface BigTokenBadgeProps extends BoxProps {
  token: Token;
  isSelected: boolean;
  isDisabled: boolean;
  onTokenClick?(token: Token): void;
}

const BigTokenBadge = memo(function BigTokenBadge({
  token,
  isSelected,
  isDisabled,
  onTokenClick,
  ...boxProps
}: BigTokenBadgeProps): ReactElement {
  const handleClick = useCallback(() => onTokenClick?.(token), [
    onTokenClick,
    token,
  ]);

  return (
    <Flex
      {...boxProps}
      p={3}
      pl="24px"
      sx={{
        alignItems: "center",
        borderRadius: "32px",
        backgroundColor: isSelected ? "orange.4" : "#1a1a1a",
        cursor: isDisabled ? "not-allowed" : "pointer",
        color: isDisabled ? "gray.6" : "white",
        userSelect: "none",
        transition: "transform ease-in-out 100ms",
        transform: "initial",

        ":hover": {
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? "orange.5"
            : "#141414",
          transform: isDisabled ? null : "scale(1.02)",
          ":active": {
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? "orange.6"
              : "black",
          },
        },
      }}
      onClick={handleClick}
    >
      <TokenIcon src={token.url} diameter={64} isDisabled={isDisabled} />
      <Box ml={3}>
        <Text my="-8px" sx={{ fontSize: 4, fontWeight: "bold" }}>
          {token.symbol}
        </Text>
        <Text sx={{ fontSize: "12px" }}>
          Token price: {token.ethPrice} ETH
          <br />
          Market cap: {token.ethMarketCap} ETH
        </Text>
      </Box>
    </Flex>
  );
});
export default BigTokenBadge;

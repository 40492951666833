/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, BoxProps, Box } from "theme-ui";
import { tokenBadgeShapeSx } from "./TokenBadge";

const EmptyTokenBadge = memo(function EmptyTokenBadge(
  props: BoxProps,
): ReactElement {
  return (
    <Box
      {...props}
      sx={{
        ...tokenBadgeShapeSx,
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: "white",
      }}
    />
  );
});
export default EmptyTokenBadge;

/** @jsx jsx */
import { memo, ReactElement, useState, useCallback, useMemo } from "react";
import { jsx, BoxProps, Box, Flex, Text, SxStyleProp } from "theme-ui";
import { chainFrom, range } from "transducist";
import { Loadable } from "../../redux/loadable";
import {
  Token,
  TokenSelection,
  TokenAddress,
  MAX_SELECTED_TOKENS,
} from "../../redux/root";
import { emptyArray } from "../../util/empty";
import { assertNever } from "../../util/typeAssertions";
import { Comparator, compareBy, withReversed } from "../util/comparators";
import BigTokenBadge from "./BigTokenBadge";

export interface TokenListProps extends BoxProps {
  tokens: Loadable<Token[]>;
  selectedTokens: TokenSelection[];
  selectToken(address: TokenAddress): void;
  unselectToken(address: TokenAddress): void;
}

enum SortType {
  MARKET_CAP = "marketCap",
  ALPHABETICAL = "alphabetical",
}

const TokenList = memo(function TokenList({
  tokens,
  selectedTokens,
  selectToken,
  unselectToken,
  ...boxProps
}: TokenListProps): ReactElement {
  const [sortType, setSortType] = useState(SortType.MARKET_CAP);

  const sortedTokens = useMemo(
    () =>
      (tokens.value ?? emptyArray).slice().sort(getTokenComparator(sortType)),
    [tokens.value, sortType],
  );

  const selectedTokenAddresses = useMemo(
    () =>
      chainFrom(selectedTokens)
        .map((token) => token.tokenAddress)
        .toSet(),
    [selectedTokens],
  );

  const handleTokenClick = useCallback(
    (token: Token) => {
      if (selectedTokenAddresses.has(token.address)) {
        unselectToken(token.address);
      } else if (selectedTokenAddresses.size < MAX_SELECTED_TOKENS) {
        selectToken(token.address);
      }
    },
    [selectToken, unselectToken, selectedTokenAddresses],
  );

  const isAtMaxSelection = selectedTokenAddresses.size === MAX_SELECTED_TOKENS;
  const badgeSizeSx: SxStyleProp = { mx: 2, my: 2, flex: "1 0 257px" };

  // Add placeholder blank boxes to the bottom of the list so badges in an
  // incomplete bottom row don't stretch to fill the full width.
  return (
    <Box {...boxProps}>
      {renderHeader()}
      <Flex mt={3} sx={{ flexWrap: "wrap" }}>
        {sortedTokens.map((token) => {
          const isSelected = selectedTokenAddresses.has(token.address);
          return (
            <BigTokenBadge
              key={token.address}
              sx={badgeSizeSx}
              token={token}
              isSelected={isSelected}
              isDisabled={isAtMaxSelection && !isSelected}
              onTokenClick={handleTokenClick}
            />
          );
        })}
        {chainFrom(range(3))
          .map((i) => <Box key={i} sx={badgeSizeSx} />)
          .toArray()}
      </Flex>
    </Box>
  );

  function renderHeader(): ReactElement {
    return (
      <Flex
        px={4}
        sx={{
          alignItems: "baseline",
          justifyContent: "space-between",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "gray.3",
        }}
      >
        <Text sx={{ fontSize: 3, fontWeight: "medium" }}>All tokens</Text>
        <Flex sx={{ fontWeight: "bold" }}>
          <HeaderTab
            text="Market Cap"
            sortType={SortType.MARKET_CAP}
            selectedSortType={sortType}
            onSelectType={setSortType}
          />
          <HeaderTab
            text="Alphabetical"
            sortType={SortType.ALPHABETICAL}
            selectedSortType={sortType}
            onSelectType={setSortType}
          />
        </Flex>
      </Flex>
    );
  }
});
export default TokenList;

interface HeaderTabProps extends BoxProps {
  sortType: SortType;
  selectedSortType: SortType;
  text: string;
  onSelectType(sortType: SortType): void;
}

const HeaderTab = memo(function HeaderTab({
  sortType,
  selectedSortType,
  text,
  onSelectType,
  ...boxProps
}: HeaderTabProps): ReactElement {
  const handleClick = useCallback(() => onSelectType(sortType), [
    onSelectType,
    sortType,
  ]);
  const isSelected = sortType === selectedSortType;

  return (
    <Box
      {...boxProps}
      ml={4}
      sx={{
        color: isSelected ? "orange.4" : "inherit",
        borderBottomWidth: 4,
        borderBottomStyle: isSelected ? "solid" : "none",
        borderBottomColor: "orange.4",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      {text}
    </Box>
  );
});

function getTokenComparator(sortType: SortType): Comparator<Token> {
  const byMarketCap = withReversed((token: Token) => token.ethMarketCap);
  const bySymbol = (token: Token) => token.symbol;
  switch (sortType) {
    case SortType.MARKET_CAP:
      return compareBy(byMarketCap, bySymbol);
    case SortType.ALPHABETICAL:
      return compareBy(bySymbol, byMarketCap);
    default:
      assertNever(sortType);
  }
}

/** @jsx jsx */
import { memo, ReactElement, useMemo } from "react";
import { jsx, BoxProps, Box, Text, Flex } from "theme-ui";
import { chainFrom, range } from "transducist";
import {
  TokenSelection,
  Token,
  MAX_SELECTED_TOKENS,
  getRemainingAllocation,
  TokenAddress,
} from "../../redux/root";
import EmptyTokenBadge from "../common/EmptyTokenBadge";
import TokenBadge from "../common/TokenBadge";
import AllocationInput from "./AllocationInput";

export interface SelectedTokensViewProps extends BoxProps {
  tokens: Token[];
  selectedTokens: TokenSelection[];
  setAllocation(tokenAddress: TokenAddress, allocation: number): void;
}

const SelectedTokensView = memo(function SelectedTokensView({
  tokens,
  selectedTokens,
  setAllocation,
  ...boxProps
}: SelectedTokensViewProps): ReactElement {
  const tokensByAddress = useMemo(
    () =>
      chainFrom(tokens).toMap(
        (token) => token.address,
        (token) => token,
      ),
    [tokens],
  );

  return (
    <Box
      {...boxProps}
      px={4}
      py={4}
      sx={{ borderRadius: "40px", backgroundColor: "#0B162F" }}
    >
      {renderAmountRemaining()}
      <Flex mt={4} sx={{ justifyContent: "space-between" }}>
        {chainFrom(range(MAX_SELECTED_TOKENS))
          .map((i) => {
            if (i < selectedTokens.length) {
              const { tokenAddress, allocation } = selectedTokens[i];
              const token = tokensByAddress.get(tokenAddress);
              if (!token) {
                throw new Error("Selected token address not found.");
              }
              return (
                <Box key={tokenAddress}>
                  <TokenBadge token={token} />
                  <AllocationInput
                    mt={2}
                    tokenAddress={tokenAddress}
                    allocation={allocation}
                    setAllocation={setAllocation}
                  />
                </Box>
              );
            } else {
              return (
                <Box key={`empty:${i}`}>
                  <EmptyTokenBadge />
                  <AllocationInput
                    mt={2}
                    tokenAddress={undefined}
                    allocation={0}
                    disabled={true}
                  />
                </Box>
              );
            }
          })
          .toArray()}
      </Flex>
    </Box>
  );

  function renderAmountRemaining(): ReactElement {
    const remainingAllocation = getRemainingAllocation(selectedTokens);
    return (
      <Text sx={{ fontSize: 2 }}>
        <span sx={{ fontWeight: "bold", color: "orange.4" }}>
          Amount remaining:
        </span>{" "}
        <span sx={{ color: remainingAllocation < 0 ? "red.4" : "inherit" }}>
          {remainingAllocation} ETH
        </span>
      </Text>
    );
  }
});
export default SelectedTokensView;

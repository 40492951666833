/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, Image, BoxProps, Box } from "theme-ui";

export interface TokenIconProps extends BoxProps {
  src: string | undefined;
  diameter: number;
  isDisabled?: boolean;
}

const TokenIcon = memo(function TokenIcon({
  src,
  diameter,
  isDisabled,
  ...boxProps
}: TokenIconProps): ReactElement {
  return (
    <Box
      {...boxProps}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: `${diameter}px`,
        overflow: "hidden",
        width: `${diameter}px`,
        height: `${diameter}px`,
        backgroundColor: "gray.3",
      }}
    >
      {isDisabled && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "black",
            opacity: 0.5,
          }}
        />
      )}
      {src ? (
        <Image src={src} width={diameter} height={diameter} />
      ) : (
        <Box ml={1} sx={{ color: "gray.7", fontSize: 3 }}>
          ?
        </Box>
      )}
    </Box>
  );
});
export default TokenIcon;

import { enterContest } from "../http/endpoints";
import {
  AppThunkAction,
  AppDispatch,
  RootState,
  isValidAllocation,
} from "./root";

export function enterContestAction(): AppThunkAction<Promise<void>> {
  return async (
    dispatch: AppDispatch,
    getState: () => RootState,
  ): Promise<void> => {
    const { selectedTokens, contestInfo } = getState();
    if (!isValidAllocation(selectedTokens)) {
      throw new Error("Cannot enter competition with invalid allocation.");
    }
    if (!contestInfo.hasValue) {
      throw new Error("Cannot enter competition before info is loaded.");
    }
    await enterContest(contestInfo.value.entryFee, selectedTokens);
  };
}

/** @jsx jsx */
import { memo, ReactElement, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { jsx, Flex, Box, Button } from "theme-ui";
import Layout from "../components/Layout";
import SelectedTokensView from "../components/play/SelectedTokensView";
import TokenList from "../components/play/TokenList";
import { titleSx, buttonSx } from "../components/style";
import { useBindActionCreators } from "../components/util/hooks";
import {
  RootState,
  selectTokenAction,
  unselectTokenAction,
  fetchTokensAction,
  setAllocationAction,
  TokenAddress,
  isValidAllocation,
} from "../redux/root";
import { enterContestAction } from "../redux/thunks";
import { emptyArray } from "../util/empty";

const PlayPage = memo(function PlayPage(): ReactElement {
  const tokens = useSelector((state: RootState) => state.tokens);
  const selectedTokens = useSelector(
    (state: RootState) => state.selectedTokens,
  );
  const {
    fetchTokens,
    selectToken,
    unselectToken,
    setAllocation,
    enterContest,
  } = useBindActionCreators({
    fetchTokens: fetchTokensAction,
    selectToken: selectTokenAction,
    unselectToken: unselectTokenAction,
    setAllocation: setAllocationAction,
    enterContest: enterContestAction,
  });

  const handleSetAllocation = useCallback(
    (tokenAddress: TokenAddress, allocation: number) =>
      setAllocation({ tokenAddress, allocation }),
    [],
  );

  useEffect(() => {
    fetchTokens();
  }, []);

  return (
    <Layout title="Choose Tokens">
      <Flex
        mx={4}
        sx={{ alignItems: "center", justifyContent: "space-between" }}
      >
        <Box sx={titleSx}>Choose 5 tokens</Box>
        <Button
          sx={buttonSx}
          disabled={!isValidAllocation(selectedTokens)}
          onClick={enterContest}
        >
          Submit
        </Button>
      </Flex>
      <SelectedTokensView
        mt={3}
        tokens={tokens.value ?? emptyArray}
        selectedTokens={selectedTokens}
        setAllocation={handleSetAllocation}
      />
      <TokenList
        mt={5}
        tokens={tokens}
        selectedTokens={selectedTokens}
        selectToken={selectToken}
        unselectToken={unselectToken}
      />
    </Layout>
  );
});
export default PlayPage;

/** @jsx jsx */
import {
  memo,
  ReactElement,
  ChangeEvent,
  useCallback,
  ComponentProps,
  useState,
  FocusEvent,
} from "react";
import { jsx, Input } from "theme-ui";
import { TokenAddress, clampAllocation } from "../../redux/root";

export interface AllocationInputProps extends ComponentProps<typeof Input> {
  tokenAddress: TokenAddress | undefined;
  allocation: number;
  setAllocation?(tokenAddress: TokenAddress, allocation: number): void;
}

const NUMERIC_PATTERN = /^\d*$/;

const AllocationInput = memo(function AllocationInput({
  tokenAddress,
  allocation,
  setAllocation,
  ...inputProps
}: AllocationInputProps): ReactElement {
  const [inputValue, setInputValue] = useState("" + allocation);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    // Hack: we want the text input to be selected on focus, but we also replace
    // the text immediately on focus from e.g. "200 ETH" to just "200" which
    // causes the selection to clear. Hence, send the select command to the end
    // of the event queue so it runs after the text updates.
    const input = event.currentTarget;
    setTimeout(() => input.select(), 0);
  }, []);
  const handleBlur = useCallback(() => {
    setIsFocused(false);
    // Snap the allocation to the allowed range on blur. This allows the user to
    // temporarily have an invalid allocation while editing, but a valid one
    // once done.
    //
    // This is important so that if for example they have deleted all the text
    // in the middle of editing, it is treated as allocation 0 rather than
    // MIN_ALLOCATION for the purposes of displaying their remaining allocation,
    // avoiding some unintuitive behavior.
    const clampedAllocation = clampAllocation(allocation);
    setInputValue("" + clampedAllocation);
    if (tokenAddress) {
      setAllocation?.(tokenAddress, clampedAllocation);
    }
  }, [tokenAddress, setAllocation, allocation]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const valueString = event.currentTarget.value;
      if (!valueString.match(NUMERIC_PATTERN)) {
        return;
      }
      setInputValue(valueString);
      // See above comment about temporarily allowing allocations outside the
      // allowed bounds.
      if (tokenAddress) {
        setAllocation?.(tokenAddress, +valueString);
      }
    },
    [setAllocation],
  );

  return (
    <Input
      {...inputProps}
      p={0}
      py={1}
      sx={{
        border: "none",
        borderBottomWidth: 4,
        borderBottomStyle: "solid",
        borderBottomColor: "gray.5",
        borderRadius: 0,
        textAlign: "center",
        fontFamily: "'Saira', sans-serif",
        fontWeight: "medium",
        ":disabled": {
          color: "gray.7",
          borderBottomColor: "gray.7",
        },
      }}
      value={isFocused ? inputValue : `${allocation} ETH`}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
    ></Input>
  );
});
export default AllocationInput;

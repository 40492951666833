/** @jsx jsx */
import { memo, ReactElement } from "react";
import { jsx, BoxProps, Box, Flex, Text, SxStyleProp } from "theme-ui";
import { Token } from "../../redux/root";
import TokenIcon from "./TokenIcon";

export interface TokenBadgeProps extends BoxProps {
  token: Token;
}

export const tokenBadgeShapeSx: SxStyleProp = {
  width: "202px",
  height: "130px",
  borderRadius: "24px",
};

const TokenBadge = memo(function TokenBadge({
  token,
  ...boxProps
}: TokenBadgeProps): ReactElement {
  return (
    <Box
      {...boxProps}
      p={3}
      sx={{
        ...tokenBadgeShapeSx,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "orange.4",
        backgroundColor: "gray.9",
      }}
    >
      <Flex sx={{ height: "48px", alignItems: "center" }}>
        <TokenIcon src={token.url} diameter={48} />
        <Text ml={2} sx={{ fontSize: 4, fontWeight: "bold" }}>
          {token.symbol}
        </Text>
      </Flex>
      <Text mt={2} sx={{ fontSize: "12px" }}>
        Token price: {token.ethPrice} ETH
        <br />
        Market cap: {token.ethMarketCap} ETH
      </Text>
    </Box>
  );
});
export default TokenBadge;
